export const SESSION_CACHE_KEY = 'session'
export const SET = 'session//SET'

export function setSession(data) {
  if (data?.token) {
    sessionStorage.setItem(SESSION_CACHE_KEY, JSON.stringify({ data }))
  }
  return {
    type: SET,
    payload: data,
  }
}

export function getSession(store) {
  return store.session.data
}

export const getSessionInitialState = () => {
  const isNew = window.location.search.includes('id=')
  if (isNew) {
    sessionStorage.removeItem(SESSION_CACHE_KEY)
  }
  const sessionJSON = sessionStorage.getItem(SESSION_CACHE_KEY)
  return isNew || !sessionJSON
    ? {
        data: {
          id: '',
          token: '',
          pin: '',
          completed: false,
        },
      }
    : JSON.parse(sessionJSON)
}

// eslint-disable-next-line func-names, default-param-last
export default function (state = getSessionInitialState(), action) {
  switch (action.type) {
    case SET:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      }

    default:
      return state
  }
}
