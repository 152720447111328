export const FETCH_DATASTORAGE_PENDING =
  'dataStorage//FETCH_DATASTORAGE_PENDING'
export const FETCH_DATASTORAGE_DONE = 'dataStorage//FETCH_DATASTORAGE_DONE'
export const FETCH_DATASTORAGE_ERROR = 'dataStorage//FETCH_DATASTORAGE_ERROR'

export function pending() {
  return {
    type: FETCH_DATASTORAGE_PENDING,
    payload: {},
  }
}

export function done(data) {
  return {
    type: FETCH_DATASTORAGE_DONE,
    payload: {
      data,
    },
  }
}

export function error(err) {
  return {
    type: FETCH_DATASTORAGE_ERROR,
    payload: {
      err,
    },
  }
}

export function getDataStorageData(store) {
  return store.dataStorage.data
}

const initialState = {
  pending: false,
  data: {},
  error: null,
}

// eslint-disable-next-line func-names, default-param-last
export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_DATASTORAGE_PENDING:
      return {
        ...state,
        data: {},
        pending: true,
        error: null,
      }

    case FETCH_DATASTORAGE_DONE:
      return {
        ...state,
        data: action.payload.data,
        pending: false,
        error: null,
      }
    case FETCH_DATASTORAGE_ERROR:
      return {
        ...state,
        data: {},
        pending: false,
        error: true,
      }

    default:
      return state
  }
}
