export const FETCH_GET_DEALERS_PENDING = 'dealers//FETCH_GET_DEALERS_PENDING'
export const FETCH_GET_DEALERS_DONE = 'dealers//FETCH_GET_DEALERS_DONE'
export const FETCH_GET_DEALERS_ERROR = 'dealers//FETCH_GET_DEALERS_ERROR'

export function pending() {
  return {
    type: FETCH_GET_DEALERS_PENDING,
  }
}

export function done(data) {
  return {
    type: FETCH_GET_DEALERS_DONE,
    payload: {
      data,
    },
  }
}

export function error(err) {
  return {
    type: FETCH_GET_DEALERS_ERROR,
    payload: {
      err,
    },
  }
}

export function getDealersData(store) {
  return store.dealers.data || {}
}

const initialState = {
  pending: false,
  data: {},
  error: null,
}

// eslint-disable-next-line func-names, default-param-last
export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_GET_DEALERS_PENDING:
      return {
        ...state,
        data: {},
        pending: true,
        error: null,
      }

    case FETCH_GET_DEALERS_DONE:
      return {
        ...state,
        data: action.payload.data,
        pending: false,
        error: null,
      }
    case FETCH_GET_DEALERS_ERROR:
      return {
        ...state,
        data: {},
        pending: false,
        error: true,
      }

    default:
      return state
  }
}
