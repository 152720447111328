import { createStore, combineReducers } from 'redux'

import formReducer from './features/form.redux'
import dataModelsReducer from './features/data-models.redux'
import dealersReducer from './features/dealers.redux'
import sessionReducer from './features/session.redux'
import dataStorageReducer from './features/data-storage.redux'
import keycloakReducer from './features/keycloak.redux'

/* eslint-disable no-underscore-dangle */
const store = createStore(
  combineReducers({
    form: formReducer,
    dataModel: dataModelsReducer,
    dealers: dealersReducer,
    session: sessionReducer,
    dataStorage: dataStorageReducer,
    keycloak: keycloakReducer,
  }),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)
/* eslint-enable */

export { store }
