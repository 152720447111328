export const SET_KEYCLOAK_DATA = 'keycloak//SET_KEYCLOAK_DATA'
export const SET_KEYCLOAK_IDP = 'keycloak//SET_KEYCLOAK_IDP'
export const SET_KEYCLOAK_IDP_HINT = 'keycloak//SET_KEYCLOAK_IDP_HINT'
export const SET_IS_LOGGED = 'keycloak//SET_IS_LOGGED'

export function setKeycloakData(data) {
  return {
    type: SET_KEYCLOAK_DATA,
    payload: data,
  }
}

export function setKeycloakIdp(idp) {
  return {
    type: SET_KEYCLOAK_IDP,
    payload: idp,
  }
}

export function setKeycloakIdpHint(idpHint) {
  return {
    type: SET_KEYCLOAK_IDP_HINT,
    payload: idpHint,
  }
}

export function setIsLogged(isLogged) {
  return {
    type: SET_IS_LOGGED,
    payload: isLogged,
  }
}

export function getKeycloakData(state) {
  return state.keycloak.data
}
export function getKeycloakIdp(state) {
  return state.keycloak.idp
}
export function getIsLogged(state) {
  return state.keycloak.isLogged
}

const initialState = {
  data: {},
  idp: null,
  idpHint: null,
  isLogged: false,
}

// eslint-disable-next-line func-names, default-param-last
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_KEYCLOAK_DATA:
      return {
        ...state,
        data: action.payload,
      }

    case SET_KEYCLOAK_IDP:
      return {
        ...state,
        idp: action.payload,
      }

    case SET_KEYCLOAK_IDP_HINT:
      return {
        ...state,
        idpHint: action.payload,
      }
    case SET_IS_LOGGED:
      return {
        ...state,
        isLogged: action.payload,
      }

    default:
      return state
  }
}
