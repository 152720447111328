export const getBrandName = () =>
  process.env.REACT_APP_WEBSITE_BRAND === 'vw5' ||
  process.env.REACT_APP_WEBSITE_BRAND === 'vw'
    ? 'vw6'
    : process.env.REACT_APP_WEBSITE_BRAND
export const getKeycloakBrand = () =>
  getBrandName().startsWith('vw') ? 'vw' : getBrandName()

export const CONNECTION_TIMEOUT = 30000

export const FILE_UPLOAD_STATUS = {
  SUCCESS: 'SUCCESS',
  LOADING: 'LOADING',
  UNSUPPORTED: 'UNSUPPORTED',
  TOO_BIG: 'TOO_BIG',
  ERROR: 'ERROR',
  PREFILLED: 'PREFILLED',
}

export const brandWebsites = {
  vw: 'https://www.volkswagen.ie',
  vw6: 'https://www.volkswagen.ie',
  vwfs: 'https://www.volkswagen.ie',
  vwcv: 'https://www.volkswagen.ie',
  skoda: 'https://www.skoda.ie',
  seat: 'https://www.seat.ie',
  audi: 'https://www.audi.ie',
  cupra: 'https://www.cupraofficial.ie',
}

export const IS_LOGIN_COMEBACK = 'is_login_comeback'

export const ANALYTICS_DEFAULTS = {
  core: {
    dataLayerVersion: '2.7',
    pageInfo: {
      /*
        The values for pagename are defined in tracking specification
        document.
        */
      pageName: null,

      /*
        @Developers: Constant value.
        Acceptance criteria:
        - Do not change
        */
      intendedCustomerDeviceType: 'all',
      version: 'R1.6',
      releaseDate: '2021-03-24',
      language: 'en',

      /*
        @Developers: Constant value.
        Acceptance criteria:
        - Do not change
        */
      market: 'IE',

      /*
        @Developers: Constant value.
        Acceptance criteria:
        - Do not change
        */
      publisher: 'DU',
    },
    category: {
      /*
        @Developers: Constant value.
        Acceptance criteria:
        - Do not change
        */
      primaryCategory: 'Financing',
      secondaryCategory: 'AutoCredit & ClassicCredit',
      siteType: 'checkoutSite',
      maturityLevel: 'Lead',
    },
    attributes: {
      /*
        @Developers: Constant value.
        Acceptance criteria:
        - Do not change
        */
      journeyType: 'customer-facing-journey',
      viewChange: null,
      brand: getBrandName() === 'vw6' ? 'vw' : getBrandName(),
    },
  },
  /*
@Developers:
Write here if the customer sees a 404, 401, etc. page
*/
  error: {
    // Error code of application exceptions or caught errors
    errorCode: null,

    // Error message for caught errors with available message
    errorMessage: null,

    // Full URL which caused the error
    errorCausingURL: null,
  },
  /*
This structure is filled as soon as the user provides information regarding
any product.

Please note that this structure is an array structure. We expect one entry
for each contract sold. If the customer can bundle his contract (i.e. get a
leasing contract, a service and maintenance package, and a tire package,
this array should have three entries.
*/
  product: [
    {
      /*
    @Developers: Constant value.
    Acceptance criteria:
    - Do not change
    */
      name: null,
      attributes: {
        typeOfSale: 'Online sales',
        paymentFrequency: 'monthly payment',
        currency: 'EUR',
        recurringPayment: null,
        duration: null,
        durationUnit: null,
        yearlyMileage: null,
        excessMileage: null,
        mileageUnit: 'KILOMETERS',
        balloonPayment: null,
        contractAmount: null,
        netLoanAmount: null,
        downPaymentAmount: null,
        nominalInterestRate: null,
        nominalInterestAmount: null,
      },
      vehicleModel: [
        {
          manufacturer: null,
          name: null,
          descriptionLong: null,
          currency: 'EUR',
          endPrice_localCurrency: null,
          typeOfUse: 'Private',
          condition: 'New car',
        },
      ],
      // productVariants: ['AutoCredit'],
      category: 'Finance',
    },
  ],
  dealerData: {
    companyId: null,
    companyName: null,
    address: {
      street: null,
      zipCode: null,
      city: null,
      state: null,
    },
  },
  form: {
    type: null,
    name: null,
    fieldValues: null,
    lastTouchedField: null,
    errorFields: null,
  },
  design: {
    browserResolutionBreakpoint: null,
  },
  customerData: {
    loginStatus: false,
    loggedInUserGroup: 'private',
    yearOfBirth: null,
    gender: null,
    address: {
      zipCode: null,
    },
  },
  dataPrivacyStatement: {
    allowPostalAds: null,
    allowPhoneAds: null,
    allowElectronicAds: null,
    allowMailAds: null,
    allowElectronicInvoices: null,
  },
  event: [
    {
      eventInfo: {
        /*
        The type of events which occurred. This information describes the
        context in which the current events occurred.
        */
        eventType: null,
        /*
        The event which occurred.
        */
        eventAction: null,
        /*
        If a link or button triggers this event, please provide the target url
        of this link
        */
        eventTargetURL: null,
        /*
        If a link or button triggers this event, please provide the name of
        this link, e.g. link title. If link title is non-unique or not
        meaningful please extend with helpful terms.
        */
        linkInformation: null,
      },
    },
  ],
}
