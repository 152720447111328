import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'

import { Router, withRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import initI18n from './services/i18n'
import { history } from './services/routing'
import { store } from './services/redux'

import './services/common/validation'

import { getBrandName } from './config'

const ConnectedApp = React.lazy(() => import('./App'))

const chooseVwBrand = (brand) => {
  switch (brand) {
    case 'vwfs':
      return 'bluelabel'
    case 'vw6':
      return 'vw6'
    case 'vwcv':
      return 'vw6'
    case 'vwn':
      return 'vw6'
    default:
      return brand
  }
}
const getStyles = (brand) =>
  import(
    `@vwfs-bronson/bronson-${
      brand.includes('vw') ? chooseVwBrand(brand) : brand
    }/dist/css/style.min.css`
  ).then(() => import('./styles.scss'))

const PositionRestore = withRouter((props) => {
  const [location, setLocation] = React.useState(props.location.pathname)
  if (location !== props.location.pathname) {
    setLocation(props.location.pathname)
    if (
      (props.location.state && props.location.state.sectionId === undefined) ||
      (props.location.state &&
        props.location.state.sectionId &&
        props.location.state.sectionId === '')
    ) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  return null
})

function Init() {
  const [loaded, setLoaded] = React.useState(false)
  React.useEffect(() => {
    Promise.all([getStyles(getBrandName()), initI18n(getBrandName())]).then(
      () => {
        setLoaded(true)
      }
    )
  }, [])
  return loaded ? (
    <React.Suspense fallback={null}>
      <Provider store={store}>
        <Router history={history}>
          <PositionRestore />
          <ConnectedApp />
        </Router>
      </Provider>
    </React.Suspense>
  ) : null
}

if (module.hot) {
  module.hot.accept(render)
}

function render() {
  const rootElement = document.getElementById('root')
  ReactDOM.render(<Init />, rootElement)
}

render()
